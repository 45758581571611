import * as React from "react";
import "@fontsource/dm-sans";
import "./nous-trouver.sass";
import Layout from "../components/Layout";

// markup
const NousTrouver = () => {
  return (
    <Layout
      title="Nous trouver"
      description="Legalify.ch - Nous trouver : nos locaux se trouvent à la Rue des Alpes 15, 1201 Genève, Suisse, proches de la Gare Cornavin."
    >
      <div id="findus-holder">
        <h5>Accès</h5>
        <h2>Nous trouver</h2>
        <p>
          Nous vous recevons dans nos locaux à la Rue des Alpes 15 à Genève :
        </p>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2761.065807337741!2d6.144125115582549!3d46.20914587911684!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478c65265cfc4e33%3A0x6e34026ed74b235a!2sRue%20des%20Alpes%2015%2C%201201%20Gen%C3%A8ve!5e0!3m2!1sen!2sch!4v1642368557063!5m2!1sen!2sch"
          loading="lazy"
          title="google-map"
        ></iframe>
      </div>
    </Layout>
  );
};

export default NousTrouver;
